<template>
  <main>
    <Navbar></Navbar>
    <NavBox></NavBox>
    <div>
      <div style="padding: 30px; display: flex; justify-content: space-around">
        <van-button @click="search" type="primary">查询质押</van-button>
        <van-button @click="getdataList()" type="primary">查询所有数据</van-button>
        <van-button @click="addNewRecord()" >添加自定义记录</van-button>
      </div>
      <div class="tableBox">
        <div style="text-align: right">总行数： {{dataList.length}}</div>
        <table>
          <thead>
            <tr>
              <th>bep20钱包</th>
              <th>信用分</th>
              <th>DeeperChain</th>
              <th>质押数量</th>
              <th>创建时间</th>
              <th>SN</th>
              <th>信用同步设置</th>
              <th>当前信用</th>
              <th>当前信用更新时间</th>
              <th>haveSent</th>
              <th>checkSet</th>
              <th>isInit</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr @click="showItem(item)" v-for="item in dataList" :key="item._id + 'r1'">
              <td>{{ item.bep20 }}</td>
              <td>
                {{item.fromCredit}} - {{item.toCredit}}
              </td>
              <td>
                {{item.deeperChain}}
              </td>
              <td>
                {{(item.dprAmount/1e18).toFixed(5)/1}}
              </td>
              <td>
                {{$moment(item.createdAt).format()}}
              </td>
              <td>
                {{item.sn}}
              </td>
              <td style="word-break: break-all;width: 200px">
                {{item.creditData}}
              </td>
              <td>
                {{item.nowCreditItem && item.nowCreditItem.credit}}
              </td>
              <td>
                {{item.nowCreditItem ? $moment(item.nowCreditItem.updatedAt).format() : ''}}
              </td>
              <td>{{item.haveSent}}</td>
              <td>{{item.checkSet}}</td>
              <td>{{item.isInit}}</td>
              <td>        
                <van-button size="mini" @click="deleteRecord(item)" type="danger">删除记录</van-button>
                <van-button size="mini" @click="editRecord(item)" type="info">修改记录</van-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <van-popup v-model="createModalStatue">
      <van-form style="width: 800px;" @submit="onSubmitCreate">
        <van-field
          v-model="createStr"
          name="obj"
          label="obj"
          placeholder="obj"
          type="textarea"
          rows="10"
        />
        <van-field
          v-model="creditDataStr"
          name="creditData"
          label="creditData"
          placeholder="creditData"
          type="textarea"
          rows="10"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-popup>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarAdmin";
import NavBox from '../NavBox.vue'
const parseNumberMap = {
  Zero: 0,
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
  Six: 6,
  Seven: 7,
  Eight: 8,
  Nine: 9
}
export default {
  components: {
    Navbar,
    NavBox
  },
  data() {
    return {
      dataList: [],
      createModalStatue: false,
      createStr: '',
      creditDataStr: ''
    };
  },
  mounted() {

  },
  methods: {
    addNewRecord() {
      this.createModalStatue = true
    },
    deleteRecord(item) {
      if (confirm(`确定要删除${item.bep20}这条记录吗？`)) {
        this.$fetch({
        url: "/admin/stakingRecord",
        method: 'DELETE',
        data: {
          recordId: item._id
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        console.log(res)
        if (res.success) {
          this.$Toast.success()
        }
      })
      }
    },
    editRecord(item) {
      this.createModalStatue = true
      let {creditData, nowCreditItem,...rest} = item
      this.createStr = JSON.stringify(rest).replace(/[,|{|}]/g, '\n')
      this.creditDataStr = JSON.stringify(creditData).replace(/[,|{|}]/g, '\n')
    },
    showItem(item) {
      console.log(item)
    },
    search() {
      let searchParams = prompt();
      return this.$fetch({
        url: "/admin/stakingRecord",
        params: {
          search: searchParams
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        this.dataList = res.data;
      }).finally(() => {
      })
    },
    onSubmitCreate() {
      let data = {}
      
      this.createStr.replace(/"/g, "").split('\n').filter(it => it.match(':')).forEach(it => {
        let paramData = it.split(':')
        data[paramData[0].trim()] = paramData[1].trim()
      })

      let {
        dprAmount,
        dprAmountNum,
        bep20,
        deeperChain,
        fromCredit,
        toCredit,
        sn,
        haveSent,
        checkSet,
        isInit,
        recordId
      } = data

      let creditData = {}
      this.creditDataStr.replace(/"/g, "").split('\n').filter(it => it.match(':')).forEach(it => {
        let paramData = it.split(':')
        creditData[paramData[0].trim()] = paramData[1]/1
      })
      this.$fetch({
        url: "/admin/stakingRecord",
        method: recordId?"PUT":'POST',
        data: {
          dprAmount,
          dprAmountNum,
          bep20,
          deeperChain,
          fromCredit,
          toCredit,
          sn,
          creditData,
          haveSent,
          checkSet,
          isInit,
          recordId
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        console.log(res)
        if (res.success) {
          this.$Toast.success()
          this.createModalStatue = false
        }
      })
    },
    getdataList() {
      this.$fetch({
        url: "/admin/stakingRecord",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        this.dataList = res.data;
      })
    },
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  padding: 10px 30px;
  .leftBox {
    display: flex;
  }
  .rightBox {
    display: flex;
    .formItem {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .titleBox {
        margin-right: 10px;
      }
    }
  }
}
.tableBox {
  padding: 30px;
  font-size: 12px;
  text-align: center;
  table {
    width: 100%;
    th,td {
      padding: 5px 2px;
    }
  }
}

.addressBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
</style>